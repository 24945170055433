import { Dimension } from 'crossfilter2';
import React, { forwardRef, useContext, useState } from 'react';
import 'twin.macro';

import DaysChart from './DaysChart';
import DaysChartPlaceholder from './DaysChartPlaceholder';
import { CFContext } from '../CrossFilterContext';
import ErrorMessage from '../ErrorMessage';
import { Hint } from '../Hint';
import { Title } from '../Title';
import { ToggleLink } from '../ToggleLink';
import { Datum } from '../utils';

interface DaysContainerProps {
  dimension: Dimension<Datum, Date>;
}

const DaysContainer = ({ dimension }: DaysContainerProps, ref) => {
  const { cf, error } = useContext(CFContext);
  const [unit, setUnit] = useState<'hours' | '%'>('hours');

  return (
    <div>
      <Title>
        Activity per day in{' '}
        <ToggleLink options={['hours', '%']} setValue={setUnit} value={unit}>
          {unit}
        </ToggleLink>
      </Title>
      <Hint>Drag to filter</Hint>
      {error ? (
        <ErrorMessage>Error loading sheet</ErrorMessage>
      ) : cf ? (
        <DaysChart dimension={dimension} ref={ref} unit={unit} tw="overflow-x-auto" />
      ) : (
        <DaysChartPlaceholder />
      )}
    </div>
  );
};

export default forwardRef(DaysContainer);
