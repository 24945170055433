import React, { useContext, useState } from 'react';
import ActivityChart from './ActivityChart';
import ActivityChartPlaceholder from './ActivityChartPlaceholder';
import { CFContext } from '../CrossFilterContext';
import ErrorMessage from '../ErrorMessage';
import { Hint } from '../Hint';
import { Title } from '../Title';
import { ToggleLink } from '../ToggleLink';

const ActivityContainer = ({ ...props }) => {
  const { cf, error } = useContext(CFContext);
  const [unit, setUnit] = useState<'hours' | '%'>('hours');

  return (
    <div {...props}>
      <Title>
        Totals in{' '}
        <ToggleLink options={['hours', '%']} setValue={setUnit} value={unit}>
          {unit}
        </ToggleLink>
      </Title>
      <Hint>Click to filter</Hint>
      {error ? (
        <ErrorMessage>Error loading sheet</ErrorMessage>
      ) : cf ? (
        <ActivityChart unit={unit} />
      ) : (
        <ActivityChartPlaceholder />
      )}
    </div>
  );
};

export default ActivityContainer;
