import React from 'react';
import tw from 'twin.macro';

export const GrowthChartPlaceholder = () => {
  const gray = tw`text-gray-300`;

  return (
    <svg width="600" height="150" tw="border-l-4 border-gray-300 text-gray-300">
      <defs>
        <linearGradient id="svgGradient" x1="0%" x2="100%" y1="0%" y2="100%" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor={gray.color} stopOpacity="1"></stop>
          <stop offset="100%" stopColor={gray.color} stopOpacity="0"></stop>
        </linearGradient>
      </defs>
      <line
        y1="148"
        y2="148"
        x1="0"
        x2="600"
        stroke="url(#svgGradient)"
        gradientUnits="userSpaceOnUse"
        strokeWidth="4"
      />
      <path
        d="M1,20Q64.1,78,80,80C103.85000000000001,83,136,28,160,40S216,163,240,160S296,7.999999999999998,320,20Q336,28,400,240"
        fill="none"
        strokeWidth="3"
        stroke="url(#svgGradient)"
      ></path>
    </svg>
  );
};

export default GrowthChartPlaceholder;
