import { extent } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { format } from 'd3-format';
import { quadtree } from 'd3-quadtree';
import { scaleLinear, scaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { timeDays } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import React, { forwardRef, useRef, useEffect, useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import 'twin.macro';

import { ConfigContext } from '../ConfigContext';
import { GridLines } from '../GridLines';
import { useActivityDomain } from '../sheets/useActivityDomain';
import { BodyMeasurement } from '../sheets/useMeasurementsSheet';
import { useHighDPIContext } from '../useHighDPIContext';
import { formatDay, getDateDomain } from '../utils';
import { TemperatureUnit } from '../weather/influx-weather-api';

interface BodyTemperatureChartProps {
  data: BodyMeasurement[];
  unit?: TemperatureUnit;
}

const DAY_WIDTH = 30;
const HEIGHT = 200;
const MARGIN = {
  bottom: 50,
  top: 10,
  left: 40,
  right: 20,
};

const formatTemp = format('.1f');

const BodyTemperatureChart = ({ data, unit = 'c', ...props }: BodyTemperatureChartProps, ref) => {
  const bottomAxis = useRef(null);
  const leftAxis = useRef(null);
  const canvasRef = useRef(null);
  const hoveredDatum = useRef<[number, number, Date, number]>(null);
  const { schemaWidth } = useContext(ConfigContext);

  const activityDomain = useActivityDomain() || (data && getDateDomain(data)) || [new Date(), new Date()];

  const days = timeDays.apply(this, activityDomain);
  const WIDTH =
    schemaWidth === 'default' ? days.length * DAY_WIDTH : window.innerWidth - MARGIN.left - MARGIN.right - 349;

  // Shift axis to the right with 0.5 step to mimick scaleBand
  const xAxisDomain = [activityDomain[0], activityDomain[1]];
  const scaleX = scaleTime(xAxisDomain, [0, WIDTH]);

  const scaleY = scaleLinear(
    extent(data, (d: BodyMeasurement) => d.temperature?.[unit]).map((d, i, arr) => {
      const span = (arr[1] - arr[0]) * 0.1;
      return i ? d + span : d - span;
    }),
    [HEIGHT, 0],
  );

  const xAxis =
    schemaWidth === 'default'
      ? axisBottom().scale(scaleX).tickFormat(formatDay).ticks(days.length)
      : axisBottom().scale(scaleX);
  const yAxis = axisLeft().scale(scaleY).ticks(6);

  useEffect(() => {
    select(bottomAxis.current).call(xAxis);
    select(leftAxis.current).call(yAxis);
  }, [canvasRef.current, bottomAxis.current, leftAxis.current, xAxis, yAxis]);

  const hasTemp = d => d.temperature?.[unit] !== undefined;

  const flattenedData: [number, number, Date, number][] = data
    .filter(hasTemp)
    .map(d => [scaleX(d.date), scaleY(d.temperature[unit]), d.date, d.temperature[unit]]);

  const tree = quadtree(flattenedData);

  useEffect(() => {
    const context = useHighDPIContext(canvasRef.current);

    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    flattenedData.forEach(([x, y]) => {
      context.beginPath();
      context.arc(x, y, schemaWidth === 'default' ? 4 : 2.5, 0, 2 * Math.PI);
      context.fill();
    });
  }, [canvasRef.current, flattenedData]);

  const feverHeight = Math.max(0, scaleY(38));

  return (
    <div {...props}>
      <div ref={ref} tw="relative" {...props}>
        <canvas
          ref={canvasRef}
          css={{ height: HEIGHT, width: WIDTH, left: MARGIN.left, top: MARGIN.top }}
          tw="absolute"
          data-for="bodytemptip"
          data-html
          data-tip
        />
        <svg width={WIDTH + MARGIN.left + MARGIN.right} height={HEIGHT + MARGIN.top + MARGIN.bottom}>
          <g transform={`translate(${MARGIN.left}, ${MARGIN.top})`} fill="currentColor">
            <rect width={WIDTH} height={feverHeight} tw="text-red-500" opacity="0.2" />
            <rect
              width={WIDTH}
              height={Math.max(0, scaleY(37.5)) - feverHeight}
              y={feverHeight}
              tw="text-red-500"
              opacity="0.1"
            />
          </g>
          <GridLines
            ticks={scaleY
              .ticks()
              .slice(1)
              .map(tick => scaleY(tick))}
            transform={`translate(${MARGIN.left}, ${MARGIN.top})`}
            width={WIDTH}
          />
          <g ref={bottomAxis} transform={`translate(${MARGIN.left}, ${HEIGHT + MARGIN.top})`}></g>
          <g ref={leftAxis} transform={`translate(${MARGIN.left}, ${MARGIN.top})`}></g>
        </svg>
        <ReactTooltip
          tw="p-1"
          id="bodytemptip"
          overridePosition={({ left, top }, currentEvent: MouseEvent, currentTarget: Element) => {
            hoveredDatum.current = tree.find(
              currentEvent.clientX - currentTarget.getBoundingClientRect().left,
              currentEvent.clientY - currentTarget.getBoundingClientRect().top,
              5,
            );

            // Show outside view because we cannot hide the tooltip when there is no data point
            return hoveredDatum.current ? { left, top } : { left: 999, top: 9999 };
          }}
          getContent={() => {
            if (!hoveredDatum.current) {
              return 'No data';
            }

            const [x, y, date, temperature] = hoveredDatum.current;

            return `<strong>${timeFormat('%e %B %Y %H:%M')(date)}</strong><br/>${formatTemp(
              temperature,
            )} &deg;${unit.toUpperCase()}`;
          }}
        />
      </div>
    </div>
  );
};
export default forwardRef(BodyTemperatureChart);
