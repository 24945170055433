import { Dimension } from 'crossfilter2';
import React, { useContext, useEffect, useRef, useState } from 'react';
import 'twin.macro';

import ActivityContainer from './activity/ActivityContainer';
import BodyTemperatureCharts from './body-temperature/BodyTemperatureCharts';
import DaysContainer from './days/DaysContainer';
import HoursContainer from './hours/HoursContainer';
import NGramsContainer from './ngrams/NGramsContainer';
import SchemaContainer from './schema/SchemaContainer';
import WeekDaysContainer from './weekdays/WeekDaysContainer';
import WeatherCharts from './weather/WeatherCharts';
import { CFContext } from './CrossFilterContext';
import { Datum } from './utils';

const CrossFilterCharts = () => {
  const { cf, error } = useContext(CFContext);

  const daysChartEl = useRef(null);
  const schemaEl = useRef(null);
  const bodyChartsEl = useRef(null);
  const weatherChartsEl = useRef(null);

  useEffect(() => {
    const list = [schemaEl, daysChartEl, bodyChartsEl, weatherChartsEl].filter(el => el.current !== null);
    list.forEach(el => {
      if (el.current) {
        el.current.onscroll = function () {
          // Set scroll position of other elements to scroll position of this list
          list.filter(e => e.current !== el.current).forEach(e => (e.current.scrollLeft = this.scrollLeft));
        };
      }
    });
  });

  const [dayDimension, setDayDimension] = useState<Dimension<Datum, Date>>();

  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

  useEffect(() => {
    if (cf) {
      cf.onChange(forceUpdate);
      setDayDimension(cf.dimension(d => d.date));
    }
  }, [cf]);

  return (
    <div
      tw="grid gap-4 px-4"
      css={{
        gridTemplateColumns: '300px minmax(500px, 100%)',
        justifyContent: 'start',
      }}
    >
      <HoursContainer />
      <SchemaContainer ref={schemaEl} />
      <WeekDaysContainer />
      <DaysContainer dimension={dayDimension} ref={daysChartEl} />
      <ActivityContainer />
      <NGramsContainer />
      <BodyTemperatureCharts dimension={dayDimension} ref={bodyChartsEl} />
      <WeatherCharts sheetError={error} dimension={dayDimension} ref={weatherChartsEl} />
    </div>
  );
};

export default CrossFilterCharts;
