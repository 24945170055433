import { NaturallyOrderedValue, Group, Dimension } from 'crossfilter2';
import { extent } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { brushX } from 'd3-brush';
import { interpolateArray } from 'd3-interpolate';
import { scaleBand, scaleLinear, scaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { stack } from 'd3-shape';
import { timeDay } from 'd3-time';
import { addDays, addHours } from 'date-fns';
import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import 'twin.macro';
import { ConfigContext } from '../ConfigContext';

import { useLegendSheet } from '../sheets/useLegendSheet';
import { useHighDPIContext } from '../useHighDPIContext';
import { useInterpolation } from '../useInterpolation';

import {
  Datum,
  formatDay,
  getDomainMax,
  getHoursCountGroup,
  getPercentageGroup,
  percentageAxis,
  useActivityColor,
} from '../utils';

const MARGIN = {
  bottom: 30,
  top: 10,
  left: 40,
  right: 20,
};

const CELL_WIDTH = 30;
const HEIGHT = 150;

interface DaysChartProps {
  dimension: Dimension<Datum, Date>;
  unit: 'hours' | '%';
}

type QuadtreeNode = [number, number, number, number, string]; // x, y, width, height, color

function getValue(d, key, unit) {
  switch (unit) {
    case 'hours':
      return d[key];
    case '%':
      return d.activities[key].avg;
    default:
      return 0;
  }
}

function getGroup(unit, dimension) {
  if (dimension) {
    switch (unit) {
      case 'hours':
        return getHoursCountGroup(dimension);
      case '%':
        return getPercentageGroup(dimension);
    }
  }
}

const DaysChart = ({ dimension, unit = 'hours', ...props }: DaysChartProps, ref) => {
  const canvasRef = useRef(null);

  const { data: legend = [] } = useLegendSheet();
  const activities = legend.map(d => d.key);
  const activityColor = useActivityColor();
  const { schemaWidth } = useContext(ConfigContext);

  const group: Group<Datum, NaturallyOrderedValue, unknown> = getGroup(unit, dimension);
  const data = group ? group.all() : [];

  const WIDTH = schemaWidth === 'default' ? data.length * CELL_WIDTH : window.innerWidth - MARGIN.left - 369;

  const leftAxis = useRef(null);
  const bottomAxis = useRef(null);

  const dateExtent = data.length ? extent(data, d => d.key as Date) : [new Date(), new Date()];
  const scaleXTime = scaleTime([dateExtent[0], addDays(dateExtent[1], 1)], [0, WIDTH]);
  const scaleXAxis = scaleTime([addHours(dateExtent[0], -12), addHours(dateExtent[1], 12)], [0, WIDTH]);
  const scaleX = scaleBand(
    data.map(d => d.key),
    [0, WIDTH],
  );
  const scaleY = scaleLinear([0, getDomainMax(unit, data)], [HEIGHT, 0]);

  const xAxis =
    schemaWidth === 'default' ? axisBottom().scale(scaleX).tickFormat(formatDay) : axisBottom().scale(scaleXAxis);
  const yAxis = unit === 'hours' ? axisLeft().scale(scaleY) : percentageAxis(axisLeft).scale(scaleY);

  const stackedData = stack()
    .keys(activities)
    .value((d, key) => getValue(d.value, key, unit))(data);

  useEffect(() => {
    select(bottomAxis.current).transition().duration(450).call(xAxis).select('.domain').style('display', 'none');
    select(leftAxis.current).transition().duration(450).call(yAxis).select('.domain').style('display', 'none');
  });

  const brushEl = useRef(null);

  const brush = brushX()
    .extent([
      [0, 0],
      [WIDTH, HEIGHT],
    ])
    .on('end', onBrushEnd);

  function onBrushEnd(event) {
    // Programmatic brush move (rounding)
    if (!event.sourceEvent) {
      return;
    }

    // Empty selection (clicking on canvas)
    if (!event.selection) {
      dimension.filter(null);
      return;
    }

    const dayInterval = timeDay.every(1);
    const [x0, x1] = event.selection.map(d => dayInterval.round(scaleXTime.invert(d)));

    select(this)
      .transition()
      .call(brush.move, x0 < x1 ? [x0, x1].map(scaleXTime) : null);
    dimension.filter(x0 < x1 ? [x0, x1] : null);
  }

  select(brushEl.current).call(brush);

  const GAP = WIDTH < 1000 ? 0 : 0.25;

  const flattenedData: QuadtreeNode[] = stackedData.flatMap(layer =>
    layer.map(d => {
      const yesterday = new Date(+d.data.key - 3600 * 24 * 1000);
      return [
        schemaWidth === 'default' ? scaleX(d.data.key) : scaleXTime(d.data.key),
        scaleY(d[1]),
        schemaWidth === 'default' ? scaleX.step() - 1 : scaleXTime(d.data.key) - scaleXTime(yesterday) - GAP,
        scaleY(d[0]) - scaleY(d[1]),
        activityColor(layer.key).replace('var(--tw-text-opacity)', '1'),
      ];
    }),
  );

  const interpolatedData = useInterpolation(flattenedData, 450, interpolateArray);

  useEffect(() => {
    const context = useHighDPIContext(canvasRef.current);

    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    interpolatedData.forEach(([x, y, width, height, color]) => {
      context.fillStyle = color;
      context.fillRect(x, y, width, height);
    });
  });

  return (
    <div ref={ref} tw="relative" {...props}>
      <canvas
        ref={canvasRef}
        css={{ height: HEIGHT, width: WIDTH, left: MARGIN.left, top: MARGIN.top }}
        tw="absolute pointer-events-none"
      />
      <svg width={WIDTH + MARGIN.left + MARGIN.right} height={HEIGHT + MARGIN.top + MARGIN.bottom} tw="relative">
        <g tw="fill-current" transform={`translate(${MARGIN.left}, ${MARGIN.top})`}>
          <g ref={brushEl} />
          <g ref={bottomAxis} transform={`translate(0, ${HEIGHT})`} />
          <g ref={leftAxis} />
        </g>
      </svg>
    </div>
  );
};

export default forwardRef(DaysChart);
