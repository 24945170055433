import { Dimension, Group, NaturallyOrderedValue } from 'crossfilter2';
import { max } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { format } from 'd3-format';
import { scaleBand, scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import React, { useEffect, useRef, useContext, useState } from 'react';
import 'twin.macro';

import { Datum, useActivityColor } from '../utils';
import { CFContext } from '../CrossFilterContext';
import { useLegendSheet } from '../sheets/useLegendSheet';

interface ActivityChartProps {
  unit?: 'hours' | '%';
}

const MARGIN = {
  bottom: 100,
  top: 20,
  left: 40,
};

const HEIGHT = 143;
const WIDTH = 200;
const PADDING = 0.1;

const ActivityChart = ({ unit = 'hours', ...props }: ActivityChartProps) => {
  const { cf } = useContext(CFContext);
  const { data: legend = [] } = useLegendSheet();
  const [activityFilters, setActivityFilters] = useState<string[]>([]);
  const [dimension, setDimension] = useState<Dimension<Datum, string>>();
  useEffect(() => {
    const dimension = cf.dimension(d => d.activity);
    setDimension(dimension);
  }, [cf]);

  const activities = legend.map(d => d.key);
  const activityColor = useActivityColor();

  const group: Group<Datum, NaturallyOrderedValue, number> =
    dimension && dimension.group<NaturallyOrderedValue, number>().reduceSum(() => 0.25);
  let data = group ? group.all() : [];

  if (unit === '%') {
    const total = data.reduce((acc, d) => acc + d.value, 0);
    data = data.map(({ key, value }) => ({
      key,
      value: value / total,
    }));
  }

  const leftAxis = useRef(null);
  const bottomAxis = useRef(null);

  const scaleX = scaleBand(activities, [0, WIDTH]).round(true).paddingInner(PADDING);
  const scaleY = scaleLinear([0, max(data, d => d.value)], [HEIGHT, 0]);

  const xAxis = axisBottom()
    .scale(scaleX)
    .tickFormat(d => legend.find(l => l.key === d)?.label);
  const yAxis = axisLeft()
    .scale(scaleY)
    .ticks({ hours: 10, '%': 5 }[unit], { hours: undefined, '%': format('.0%') }[unit]);

  useEffect(() => {
    const x = select(bottomAxis.current).transition().duration(450).call(xAxis);
    x.select('.domain').style('display', 'none');

    x.selectAll('text')
      .attr('y', 0)
      .attr('x', 9)
      .attr('dy', '.35em')
      .attr('transform', 'rotate(90)')
      .style('text-anchor', 'start');
    select(leftAxis.current).transition().duration(450).call(yAxis).select('.domain').style('display', 'none');
  });

  const onFilterActivities = activity => {
    const arr = [].concat(activity);
    setActivityFilters(arr);
    dimension.filter(arr.length ? d => arr.includes(d) : null);
  };

  return (
    <div {...props}>
      <svg width={WIDTH + MARGIN.left} height={HEIGHT + MARGIN.top + MARGIN.bottom}>
        <g transform={`translate(${MARGIN.left}, ${MARGIN.top})`} tw="fill-current">
          {data
            .filter(d => activities.includes(d.key as string))
            .map((d, i) => {
              return (
                <g key={d.key.toString()} transform={`translate(${scaleX(d.key)}, 0)`}>
                  <text
                    textAnchor="middle"
                    dx={scaleX.step() * (1 - PADDING) * 0.5}
                    transform={`translate(0, ${scaleY(d.value) - 3})`}
                    tw="text-xs"
                    css={{ transition: 'transform 450ms' }}
                  >
                    {{ hours: d.value, '%': format('.1%')(d.value) }[unit]}
                  </text>
                  <rect
                    onClick={e => {
                      onFilterActivities(
                        activityFilters.includes(d.key.toString())
                          ? activityFilters.filter(f => d.key !== f)
                          : [...activityFilters, d.key],
                      );
                    }}
                    key={d.key.toString()}
                    height={HEIGHT - scaleY(d.value)}
                    width={scaleX.step() * (1 - PADDING)}
                    y={scaleY(d.value)}
                    css={[
                      activityFilters.includes(d.key.toString()) && {
                        stroke: 'black',
                      },
                      {
                        color: activityColor(d.key.toString()),
                        transition: 'height 450ms, y 450ms',
                      },
                    ]}
                    tw="cursor-pointer"
                  ></rect>
                </g>
              );
            })}
        </g>
        <g ref={bottomAxis} transform={`translate(${MARGIN.left}, ${HEIGHT + MARGIN.top})`}></g>
        <g ref={leftAxis} transform={`translate(${MARGIN.left}, ${MARGIN.top})`}></g>
      </svg>
    </div>
  );
};

export default ActivityChart;
