import { extent, max } from 'd3-array';
import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
import tw from 'twin.macro';
import { useLegendSheet } from './sheets/useLegendSheet';

export interface Datum {
  activity: string;
  date: Date;
  hours: number;
  time: string;
}

export interface Aggregate {
  count: number;
  avg: number;
}

interface AggregatedActivities {
  key: Date;
  value: { d: number; f: number; h: number; s: number; w: number };
}

const formatMonth = timeFormat('%b %-d');
const formatDate = timeFormat('%-d');
export const formatDay = (d, i) => (new Date(d).getDate() === 1 || i === 0 ? formatMonth : formatDate)(d);

export const formatHour = d => (d % 1 ? '' : d + ':00');

export const activities = () => {
  const { data } = useLegendSheet();

  return data ? data.map(d => d.key) : [];
};

export const useActivityLabel = () => {
  const { data } = useLegendSheet();

  return key => (data ? data.find(d => d.key === key)?.label || 'Unknown activity' : undefined);
};

export const useActivityColor = (): ((key: string) => string) => {
  const { data } = useLegendSheet();

  return key => (data ? data.find(d => d.key === key)?.color || '#E5E7EB' : '#D1D5DB33');
};

export const placeholderFade = [{}, {}, {}, {}, {}, {}, tw`opacity-75`, tw`opacity-50`, tw`opacity-25`];

export const percentageAxis = axisFn => axisFn().tickPadding(6).ticks(3).tickFormat(format('.0%'));

export const getDomainMax = (unit, data) => {
  switch (unit) {
    case 'hours':
      return max(data, (d: AggregatedActivities) => Object.values(d.value).reduce((a, b) => a + b, 0));
    case '%':
      return 1;
  }
};

export const getHoursCountGroup = dimension =>
  dimension.group().reduce(
    (acc, d) => {
      acc[d.activity] += 0.25;
      return acc;
    },
    (acc, d) => {
      acc[d.activity] -= 0.25;
      return acc;
    },
    () => ({ s: 0, d: 0, f: 0, v: 0, w: 0, h: 0 }),
  );

const isValidActivity = (a: string, activities: { [a: string]: Aggregate }) => a && a in activities;

export const getPercentageGroup = (dimension, combineDrinkingActivities = false) =>
  dimension.group().reduce(
    (acc: { count: number; activities: { [a: string]: Aggregate } }, d: Datum) => {
      // Treat f as d
      const a = combineDrinkingActivities && d.activity === 'f' ? 'd' : d.activity;
      if (isValidActivity(a, acc.activities)) {
        acc.count++;
        acc.activities[a].count++;
        Object.keys(acc.activities).forEach(k => {
          acc.activities[k].avg = acc.count && acc.activities[k].count / acc.count;
        });
      } else if (d.activity) {
        console.warn(`"${a}" is no valid activity value on ${d.date.toDateString()} at ${d.time}`);
      }
      return acc;
    },
    (acc: { count: number; activities: { [a: string]: Aggregate } }, d: Datum) => {
      const a = combineDrinkingActivities && d.activity === 'f' ? 'd' : d.activity;
      if (isValidActivity(a, acc.activities)) {
        acc.count--;
        acc.activities[a].count--;
        Object.keys(acc.activities).forEach(k => {
          acc.activities[k].avg = acc.count && acc.activities[k].count / acc.count;
        });
      }
      return acc;
    },
    () => ({
      count: 0,
      activities: {
        s: { count: 0, avg: 0 },
        d: { count: 0, avg: 0 },
        f: { count: 0, avg: 0 },
        v: { count: 0, avg: 0 },
        w: { count: 0, avg: 0 },
        h: { count: 0, avg: 0 },
      },
    }),
  );

export const getDateDomain = <T extends { date: Date }>(data: readonly T[]): [Date, Date] => {
  const [start, end]: [Date, Date] = extent(data, d => d.date);
  const d = new Date(end.valueOf());
  return [start, new Date(d.setDate(d.getDate() + 1))];
};
