import { range } from 'd3-array';
import React from 'react';
import 'twin.macro';
import { placeholderFade } from '../utils';

const SchemaPlaceholder = ({ ...props }) => (
  <div tw="flex animate-pulse" css={{ paddingTop: 20, paddingLeft: 40 }} {...props}>
    {placeholderFade.map((d, i) => (
      <div key={i} css={[{ marginRight: 1 }, d]}>
        {range(24 * 4).map(j => (
          <div key={j} css={{ width: 50, height: 7, marginBottom: 1 }} tw="bg-gray-300" />
        ))}
      </div>
    ))}
  </div>
);

export default SchemaPlaceholder;
