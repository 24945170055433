import React from 'react';

import ScatterPlot from './ScatterPlot';
import ScatterPlotPlaceholder from './ScatterPlotPlaceholder';
import TempError from '../weather/TempError';
import ErrorMessage from '../ErrorMessage';
import { Hint } from '../Hint';
import { Title } from '../Title';
import { TemperatureUnit } from '../weather/influx-weather-api';
import { SheetError } from '../sheets/useSheet';
import { ScaleLinear } from 'd3';

interface ScatterPlotContainerProps {
  data: any[];
  error?: Error;
  isPending: boolean;
  scaleY: ScaleLinear<number, number>;
  sheetError?: SheetError;
  title: string;
  unit?: TemperatureUnit;
}

const ScatterPlotContainer = ({
  scaleY,
  data,
  error,
  isPending,
  sheetError,
  title,
  unit,
}: ScatterPlotContainerProps) => {
  return (
    <div>
      <Title>{title}</Title>
      <Hint>In &deg;{unit.toUpperCase()} and % per day</Hint>

      {isPending && <ScatterPlotPlaceholder />}
      {sheetError && <ErrorMessage>Error loading sheet</ErrorMessage>}
      {error && <TempError error={error} />}
      {data && (
        <div tw="grid gap-8">
          <ScatterPlot scaleY={scaleY} data={data} unit={unit} />
        </div>
      )}
    </div>
  );
};

export default ScatterPlotContainer;
