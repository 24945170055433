import React, { useContext, useState } from 'react';

import WeekDaysChart from './WeekDaysChart';
import ActivityChartPlaceholder from '../activity/ActivityChartPlaceholder';
import { CFContext } from '../CrossFilterContext';
import ErrorMessage from '../ErrorMessage';
import { Hint } from '../Hint';
import { Title } from '../Title';
import { ToggleLink } from '../ToggleLink';

const WeekDaysContainer = () => {
  const { cf, error } = useContext(CFContext);
  const [unit, setUnit] = useState<'hours' | '%'>('%');

  return (
    <div>
      <Title>
        Activity per weekday in{' '}
        <ToggleLink options={['hours', '%']} setValue={setUnit} value={unit}>
          {unit}
        </ToggleLink>
      </Title>
      <Hint>Drag to filter</Hint>
      {error ? (
        <ErrorMessage>Error loading sheet</ErrorMessage>
      ) : cf ? (
        <WeekDaysChart unit={unit} />
      ) : (
        <ActivityChartPlaceholder />
      )}
    </div>
  );
};

export default WeekDaysContainer;
