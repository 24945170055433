import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';
import 'twin.macro';

import Layout from '../components/layout';
import SEO from '../components/seo';
import GrowthChartsContainer from '../components/growth/GrowthChartsContainer';
import CrossFilterCharts from '../components/CrossFilterCharts';
import { CrossFilterProvider } from '../components/CrossFilterContext';
import Legend from '../components/Legend';
import { Heading } from '../components/Heading';
import { CurrentUser } from '../components/CurrentUser';

export default () => {
  const location = useLocation();

  return (
    <Layout>
      <SEO title="Home" />
      <CrossFilterProvider>
        <div tw="flex flex-wrap justify-between mx-4">
          <div>
            <Heading />
            <p tw="mb-2">
              <Link to={`/about${location.search}`} tw="cursor-pointer text-blue-500">
                About this site
              </Link>
            </p>
          </div>
          <div tw="text-right">
            <Legend tw="mb-4" />
            <CurrentUser />
          </div>
        </div>
        <CrossFilterCharts />
        <GrowthChartsContainer />
      </CrossFilterProvider>
    </Layout>
  );
};
