import React from 'react';
import 'twin.macro';

const NGramCountsPlaceholder = () => {
  return (
    <div tw="inline-grid grid-cols-5 gap-2 animate-pulse">
      {Array(25)
        .fill(null)
        .map((_, i) => (
          <div key={i} tw="w-6 h-6 bg-gray-300" />
        ))}
    </div>
  );
};

export default NGramCountsPlaceholder;
