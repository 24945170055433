import React, { forwardRef, useContext } from 'react';
import 'twin.macro';
import { Button } from '../Button';
import { ConfigContext } from '../ConfigContext';

import { CFContext } from '../CrossFilterContext';
import ErrorMessage from '../ErrorMessage';
import { Hint } from '../Hint';
import { Title } from '../Title';
import Schema from './Schema';
import SchemaPlaceholder from './SchemaPlaceholder';

const SchemaContainer = (_, ref) => {
  const { cf, error } = useContext(CFContext);
  const { schemaWidth, setSchemaWidth } = useContext(ConfigContext);

  return (
    <div>
      <Title>
        Logbook{' '}
        {schemaWidth === 'default' ? (
          <Button variant="outlined" size="small" onClick={() => setSchemaWidth('wide')}>
            Default
          </Button>
        ) : (
          <Button variant="outlined" size="small" onClick={() => setSchemaWidth('default')}>
            100%
          </Button>
        )}
      </Title>
      <Hint>All data points</Hint>
      {error ? (
        <ErrorMessage>Error loading sheet</ErrorMessage>
      ) : cf ? (
        <Schema allData={cf.all()} tw="overflow-x-auto" ref={ref} />
      ) : (
        <SchemaPlaceholder />
      )}
    </div>
  );
};

export default forwardRef(SchemaContainer);
