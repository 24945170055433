export const useHighDPIContext = canvas => {
  const dpr = window.devicePixelRatio || 1;

  const rect = canvas.getBoundingClientRect();
  canvas.width = rect.width * dpr;
  canvas.height = rect.height * dpr;

  const context = canvas.getContext('2d');
  context.scale(dpr, dpr);
  return context;
};
