import { Dimension } from 'crossfilter2';
import { extent, rollup } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import { timeDays } from 'd3-time';
import React, { forwardRef, useContext, useMemo, useState } from 'react';

import { ConfigContext } from '../ConfigContext';
import { useActivityDomain } from '../sheets/useActivityDomain';
import { BodyMeasurement, useMeasurementsSheet } from '../sheets/useMeasurementsSheet';
import { Datum, getDateDomain, getPercentageGroup } from '../utils';
import { TemperatureUnit } from '../weather/influx-weather-api';
import BodyTemperatureContainer from './BodyTemperatureContainer';
import ScatterPlotContainer from '../scatterplot/ScatterPlotContainer';
import { InternMap } from 'd3';

export interface BodyTemperatureChartsProps {
  dimension: Dimension<Datum, Date>;
}

const BodyTemperatureCharts = ({ dimension, ...props }, ref) => {
  const { spreadsheetId, bodyRange: range } = useContext(ConfigContext);
  const [unit, setUnit] = useState<TemperatureUnit>('c');
  const { data, error, pending } = useMeasurementsSheet({
    spreadsheetId,
    range,
  });

  const scatterData = useMemo<BodyMeasurement[]>(() => {
    if (dimension && data) {
      const dayGroup = getPercentageGroup(dimension, true);
      const percentageData: { key: Date; value: Datum }[] = dayGroup.all();

      const bodyMap = rollup(
        data,
        d => d[0],
        d => d.date.toDateString(),
      );

      return percentageData.reduce((acc, d) => {
        const temperature = bodyMap.get(d.key.toDateString())?.temperature;

        if (temperature) {
          acc.push({ ...d, temperature });
        }
        return acc;
      }, []);
    }
  }, [dimension, data]);

  const HEIGHT = 200;
  const scaleY = scaleLinear<number, number>(
    extent(scatterData ?? [], d => d.temperature[unit]).map((d, i, arr) => {
      const span = (arr[1] - arr[0]) * 0.1;
      return i ? d + span : d - span;
    }),
    [HEIGHT, 0],
  );

  return (
    <>
      <ScatterPlotContainer
        data={scatterData}
        isPending={pending}
        scaleY={scaleY}
        sheetError={error}
        title="Body temperature vs. activity"
        unit={unit}
      />
      <BodyTemperatureContainer
        data={data}
        ref={ref}
        error={error}
        pending={pending}
        setUnit={setUnit}
        unit={unit}
        {...props}
      />
    </>
  );
};

export default forwardRef(BodyTemperatureCharts);
