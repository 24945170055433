import React from 'react';
import tw from 'twin.macro';
import { useLegendSheet } from './sheets/useLegendSheet';

const Legend = ({ ...props }) => {
  const { data: activities } = useLegendSheet();

  if (!activities) {
    return null;
  }

  return (
    <div tw="flex" {...props}>
      <span tw="text-sm">Legend</span>
      <div tw="grid gap-1 ml-4" css={{ gridAutoFlow: 'column' }}>
        {activities.map(({ color, key, label }) => (
          <div
            key={key}
            tw="rounded px-2 whitespace-nowrap h-6 text-xs flex items-center justify-center"
            css={[{ backgroundColor: color }, tw`text-black text-opacity-75`]}
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Legend;
