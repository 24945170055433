import { axisLeft, axisTop } from 'd3-axis';
import { select } from 'd3-selection';
import { extent, range } from 'd3-array';
import { scaleBand, scaleLinear, scaleTime } from 'd3-scale';
import { timeDay, timeDays } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import React, { forwardRef, useEffect, useRef, useContext, useState } from 'react';
import 'twin.macro';

import { Datum, formatDay, formatHour, useActivityColor, useActivityLabel } from '../utils';
import { CFContext } from '../CrossFilterContext';
import ReactTooltip from 'react-tooltip';
import { ConfigContext } from '../ConfigContext';
import { useHighDPIContext } from '../useHighDPIContext';
import { addDays, addHours } from 'date-fns';

const MARGIN = {
  top: 20,
  left: 40,
  bottom: 20,
  right: 20,
};

const CELL_WIDTH = 30;
const CELL_HEIGHT = 8;
const HEIGHT = 24 * 4 * CELL_HEIGHT;

interface SchemaProps {
  allData: readonly Datum[];
}

const Schema = ({ allData, ...props }: SchemaProps, ref) => {
  const activityColor = useActivityColor();
  const activityLabel = useActivityLabel();

  const { schemaWidth } = useContext(ConfigContext);
  const { cf } = useContext(CFContext);
  const canvasRef = useRef(null);
  const hoveredDatum = useRef<Datum>(null);

  const dateExtent = extent(allData, d => d.date);
  const days = [...timeDays.apply(this, dateExtent), dateExtent[1]]; // also include highest date

  const WIDTH =
    schemaWidth === 'default' ? CELL_WIDTH * days.length : window.innerWidth - MARGIN.left - MARGIN.right - 349;

  const scaleXTime = scaleTime([dateExtent[0], addDays(dateExtent[1], 1)], [0, WIDTH]);
  // We can't use scaleBand to generate smart axis labels
  // We so we use scaleTime but position the axis as if this is scaleBand
  const scaleXAxis = scaleTime([addHours(dateExtent[0], -12), addHours(dateExtent[1], 12)], [0, WIDTH]);
  const scaleX = scaleBand(days, [0, WIDTH]);
  const scaleY = scaleLinear([0, 24], [0, HEIGHT]);

  const xAxis =
    schemaWidth === 'default'
      ? axisTop().scale(scaleX).tickFormat(formatDay).tickPadding(6)
      : axisTop().scale(scaleXAxis).tickPadding(6);
  const yAxis = axisLeft()
    .scale(scaleY)
    .tickFormat(formatHour)
    .tickValues(range(0, 48).map(d => d / 2));
  const topAxis = useRef(null);
  const leftAxis = useRef(null);

  useEffect(() => {
    select(topAxis.current).call(xAxis).select('.domain').style('display', 'none');
    select(leftAxis.current).call(yAxis).select('.domain').style('display', 'none');
  });

  const GAP = schemaWidth === 'wide' ? (days.length > 30 ? 0 : 0.25) : 1;

  useEffect(() => {
    const context = useHighDPIContext(canvasRef.current);

    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    allData.map((d, i) => {
      context.fillStyle = cf.isElementFiltered(i)
        ? activityColor(d.activity).replace('var(--tw-text-opacity)', '1')
        : activityColor(undefined);

      context.fillRect(scaleX(d.date), scaleY(d.hours) + GAP, scaleX.step() - GAP, scaleY(0.25) - scaleY(0) - GAP);
    });
  });

  return (
    <div tw="relative" ref={ref} {...props}>
      <svg width={WIDTH + MARGIN.left + MARGIN.right} height={HEIGHT + MARGIN.top + MARGIN.bottom}>
        <g>
          <g ref={topAxis} transform={`translate(${MARGIN.left}, ${MARGIN.top})`}></g>
          <g ref={leftAxis} transform={`translate(${MARGIN.left}, ${MARGIN.top})`}></g>
        </g>
      </svg>
      <canvas
        ref={canvasRef}
        tw="absolute top-0"
        css={{ height: HEIGHT, width: WIDTH, marginLeft: MARGIN.left, marginTop: MARGIN.top }}
        data-for="schematip"
        data-html
        data-tip
      />

      <ReactTooltip
        tw="p-1"
        id="schematip"
        effect="float"
        overridePosition={({ left, top }, currentEvent: MouseEvent, currentTarget: Element) => {
          const date = timeDay.floor(
            scaleXTime.invert(currentEvent.clientX - currentTarget.getBoundingClientRect().left),
          );
          const hours =
            Math.floor(scaleY.invert(currentEvent.clientY - currentTarget.getBoundingClientRect().top) * 4) / 4;
          hoveredDatum.current = allData.find(d => d.hours === hours && +d.date === +date);

          return { left, top };
        }}
        getContent={() => {
          if (!hoveredDatum.current) {
            return 'No data';
          }
          const { date, time, activity } = hoveredDatum.current;

          return `<strong>${timeFormat('%e %B %Y')(date)} ${time}</strong><br/>15 min ${activityLabel(activity)}`;
        }}
      />
    </div>
  );
};

export default forwardRef(Schema);
