import { range } from 'd3-array';
import React from 'react';
import 'twin.macro';

const HoursChartPlaceholder = ({ ...props }) => (
  <div css={{ paddingTop: 20, paddingLeft: 40 }} tw="animate-pulse" {...props}>
    {range(24 * 4).map(i => (
      <div key={i} css={{ width: 160, height: 7, marginBottom: 1 }} tw="bg-gray-300" />
    ))}
  </div>
);

export default HoursChartPlaceholder;
