import React from 'react';
import 'twin.macro';

import { Button } from './Button';
import { useAuth } from './useAuth';

export const CurrentUser = () => {
  const { currentUser, signingIn, signedIn, signIn, signOut, error } = useAuth();

  if (signingIn) {
    return null;
  }

  if (error) {
    return null;
  }

  return signedIn && currentUser ? (
    <div>
      {currentUser.getBasicProfile().getEmail()}{' '}
      <Button variant="outlined" size="small" onClick={signOut} tw="ml-2">
        Sign out
      </Button>
    </div>
  ) : (
    <Button onClick={signIn}>Sign in</Button>
  );
};
