import { ascending } from 'd3-array';
import { timeDay, timeWeek } from 'd3-time';

import boysLength13w from './boys-length-13w.json';
import boysLength24m from './boys-length-24m.json';
import boysWeight13w from './boys-weight-13w.json';
import boysWeight5y from './boys-weight-5y.json';
import girlsLength13w from './girls-length-13w.json';
import girlsLength24m from './girls-length-24m.json';
import girlsWeight13w from './girls-weight-13w.json';
import girlsWeight5y from './girls-weight-5y.json';

interface GrowthDatum {
  median?: number;
  m?: number;
  sd?: number;
  s?: number;
  l?: number;
  '1sd'?: number;
  '2sd'?: number;
  '3sd'?: number;
  '-1sd'?: number;
  '-2sd'?: number;
  '-3sd'?: number;
}

export const useGrowthStandard = ({
  gender,
  startDate,
  parameter,
  zoomLevel,
}: {
  gender: 'boy' | 'girl';
  parameter: 'weight' | 'length';
  startDate: Date;
  zoomLevel: 'weeks' | 'months' | 'years';
}): (GrowthDatum & { date: Date })[] => {
  const generateDates = (interval, data, step = 1) =>
    data.map((d, i) => ({ ...d, date: interval.offset(startDate, i * step) }));

  const weekData = generateDates(
    timeWeek,
    {
      boy: { length: boysLength13w, weight: boysWeight13w }[parameter],
      girl: { length: girlsLength13w, weight: girlsWeight13w }[parameter],
    }[gender],
  );
  const monthData = generateDates(
    timeDay,
    {
      boy: { length: boysLength24m, weight: boysWeight5y }[parameter],
      girl: { length: girlsLength24m, weight: girlsWeight5y }[parameter],
    }[gender],
    365 / 12,
  );

  // Find first month data point index after last week data point
  const i = monthData.findIndex(d => d.date > weekData[weekData.length - 1].date);
  return {
    weeks: [
      ...weekData,
      ...monthData.slice(i, i + monthData.length - weekData.length), // Pad with month data points to make week and month arrays equal size
    ],
    months: monthData,
    years: monthData,
  }[zoomLevel].sort((a, b) => ascending(a.date, b.date));
};
