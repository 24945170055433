import crossfilter, { Crossfilter } from 'crossfilter2';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { Datum } from './utils';
import { SheetError, useSheet } from './sheets/useSheet';
import { ConfigContext } from './ConfigContext';
import { matchRange } from './sheets/matchRange';

export const CFContext = createContext<{
  cf?: Crossfilter<Datum>;
  error?: SheetError;
}>(null);

export const CrossFilterProvider = ({ children }) => {
  const { spreadsheetId, activityRange: range } = useContext(ConfigContext);
  const [cf, setCf] = useState(null);

  const { data: sheetData, error } = useSheet({ spreadsheetId, range });

  const [colStart, rowStart] = matchRange(range);

  useEffect(() => {
    if (sheetData) {
      const [keys, ...rows] = sheetData;
      const [timeword, ...dateStrings] = keys;

      const data = rows.reduce<Datum[]>((arr, row, i) => {
        const [time, ...values] = row;
        values.forEach((activity, j) => {
          const [h, m] = time.split(':');
          const hours = +h + +m / 60;
          const date = new Date(dateStrings[j] + ' 0:00');

          if (isNaN(hours)) {
            console.warn(`Could not parse time in activity table`, time);
          } else if (isNaN(new Date(date).valueOf())) {
            console.log(colStart, j, rowStart);
            console.warn(
              `Could not parse date in activity table at cell ${String.fromCharCode(
                colStart.charCodeAt(0) + 1 + j,
              )}${rowStart}`,
              dateStrings[j],
            );
          } else {
            arr.push({
              date,
              time,
              hours,
              activity,
            });
          }
        });
        return arr;
      }, []);
      setCf(crossfilter(data));
    }
  }, [sheetData]);

  return <CFContext.Provider value={{ cf, error }}>{children}</CFContext.Provider>;
};
