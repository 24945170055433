import { Dimension } from 'crossfilter2';
import { extent, rollup } from 'd3-array';
import React, { forwardRef, useMemo, useState } from 'react';
import 'twin.macro';

import { TemperatureUnit, useWeather } from './influx-weather-api';
import ScatterPlotContainer from '../scatterplot/ScatterPlotContainer';
import { Datum, getPercentageGroup } from '../utils';
import WeatherContainer from './weatherchart/WeatherContainer';
import { useActivityDomain } from '../sheets/useActivityDomain';
import { SheetError } from '../sheets/useSheet';
import { scaleLinear } from 'd3-scale';

interface WeatherChartsProps {
  dimension: Dimension<Datum, Date>;
  sheetError?: SheetError;
}

const WeatherCharts = ({ dimension, sheetError, ...props }: WeatherChartsProps, ref) => {
  const [unit, setUnit] = useState<TemperatureUnit>('c');
  const dateDomain = useActivityDomain();

  const { data: weatherData, isLoading, error } = useWeather(dateDomain);

  const scatterplotData = useMemo(() => {
    if (dimension && weatherData) {
      const dayGroup = getPercentageGroup(dimension, true);
      const data = dayGroup.all();

      const allWeatherMap = rollup(
        weatherData,
        d => d[0],
        d => d.date.toDateString(),
      );

      return data.reduce((acc, d) => {
        const maxTemperature = allWeatherMap.get(d.key.toDateString())?.maxTemperature;

        if (maxTemperature) {
          acc.push({ ...d, temperature: maxTemperature });
        }
        return acc;
      }, []);
    }
  }, [dimension, weatherData]);

  const HEIGHT = 200;
  const scaleY = scaleLinear<number, number>(
    extent(scatterplotData ?? [], d => d.temperature[unit]).map((d, i, arr) => {
      const span = (arr[1] - arr[0]) * 0.1;
      return i ? d + span : Math.min(unit === 'c' ? 0 : 32, d - span);
    }),
    [HEIGHT, 0],
  );

  return (
    <>
      <ScatterPlotContainer
        data={scatterplotData}
        error={error}
        isPending={isLoading}
        scaleY={scaleY}
        sheetError={sheetError}
        title="Max temperature vs. activity"
        unit={unit}
      />
      <WeatherContainer
        data={weatherData}
        error={error}
        isPending={isLoading}
        ref={ref}
        setUnit={setUnit}
        sheetError={sheetError}
        unit={unit}
        {...props}
      />
    </>
  );
};

export default forwardRef(WeatherCharts);
