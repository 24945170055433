import { Dimension, NaturallyOrderedValue, Group } from 'crossfilter2';
import { range } from 'd3-array';
import { axisLeft, axisTop } from 'd3-axis';
import { brushY } from 'd3-brush';
import { interpolateArray } from 'd3-interpolate';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { stack } from 'd3-shape';
import React, { useContext, useEffect, useRef, useState } from 'react';
import 'twin.macro';

interface HoursChartProps {
  unit: 'hours' | '%';
}

interface AggregatedActivities {
  key: Date;
  value: { d: number; f: number; h: number; s: number; w: number };
}

import {
  formatHour,
  Datum,
  getPercentageGroup,
  percentageAxis,
  getHoursCountGroup,
  getDomainMax,
  useActivityColor,
} from '../utils';
import { CFContext } from '../CrossFilterContext';
import { useLegendSheet } from '../sheets/useLegendSheet';
import { useInterpolation } from '../useInterpolation';
import { useHighDPIContext } from '../useHighDPIContext';

const MARGIN = {
  top: 20,
  right: 20,
  bottom: 20,
  left: 40,
};

const CELL_HEIGHT = 8;
const WIDTH = 200;

function getGroup(unit, dimension) {
  if (dimension) {
    switch (unit) {
      case 'hours':
        return getHoursCountGroup(dimension);
      case '%':
        return getPercentageGroup(dimension);
    }
  }
}

function getValue(d, key, unit) {
  switch (unit) {
    case 'hours':
      return d[key];
    case '%':
      return d.activities[key].avg;
    default:
      return 0;
  }
}

const HoursChart = ({ unit = '%', ...props }: HoursChartProps) => {
  const { data: legend = [] } = useLegendSheet();
  const activities = legend.map(d => d.key);
  const activityColor = useActivityColor();

  const { cf } = useContext(CFContext);
  const [dimension, setDimension] = useState<Dimension<Datum, number>>();

  useEffect(() => {
    setDimension(cf.dimension(d => d.hours));
  }, [cf]);

  const group: Group<Datum, NaturallyOrderedValue, unknown> = getGroup(unit, dimension);

  const leftAxis = useRef(null);
  const topAxis = useRef(null);
  const canvasRef = useRef(null);
  const brushEl = useRef(null);

  const data = group ? group.all() : [];

  const HEIGHT = data.length * CELL_HEIGHT;

  const scaleX = scaleLinear([0, getDomainMax(unit, data)], [0, WIDTH]);
  const scaleY = scaleLinear([0, 24], [0, HEIGHT]);
  const scaleYLin = scaleLinear([0, 24], [0, HEIGHT]);

  const xAxis = unit === 'hours' ? axisTop().scale(scaleY) : percentageAxis(axisTop).scale(scaleX);

  const yAxis = axisLeft()
    .scale(scaleY)
    .tickFormat(formatHour)
    .tickValues(range(0, 48).map(d => d / 2));

  const stackedData = stack()
    .keys(activities)
    .value((d, key) => getValue(d.value, key, unit))(data);

  const flattenedData = stackedData.flatMap(layer =>
    layer.map(d => [
      scaleX(d[0]),
      scaleY(d.data.key) + 1,
      scaleX(d[1]) - scaleX(d[0]),
      scaleY(0.25) - scaleY(0) - 1,
      activityColor(layer.key).replace('var(--tw-text-opacity)', '1'),
    ]),
  );

  const interpolatedData = useInterpolation(flattenedData, 450, interpolateArray);

  useEffect(() => {
    const context = useHighDPIContext(canvasRef.current);

    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    interpolatedData.forEach(([x, y, width, height, color]) => {
      context.fillStyle = color;
      context.fillRect(x, y, width, height);
    });
  });

  select(topAxis.current).call(xAxis).select('.domain').style('display', 'none');
  select(leftAxis.current).call(yAxis).select('.domain').style('display', 'none');

  const brush = brushY()
    .extent([
      [0, 0],
      [WIDTH, HEIGHT],
    ])
    .on('end', onBrushEnd);

  function onBrushEnd(event) {
    // Programmatic brush move (rounding)
    if (!event.sourceEvent) {
      return;
    }

    // Empty selection (clicking on canvas)
    if (!event.selection) {
      dimension.filter(null);
      return;
    }

    const [y0, y1] = event.selection.map(d => Math.round(scaleYLin.invert(d) * 4) / 4);

    select(this)
      .transition()
      .call(brush.move, y0 < y1 ? [y0, y1].map(scaleYLin) : null);

    dimension.filter(y0 < y1 ? [y0, y1] : null);
  }

  select(brushEl.current).call(brush);

  return (
    <div tw="relative" {...props}>
      <canvas
        ref={canvasRef}
        css={{ height: HEIGHT, width: WIDTH, left: MARGIN.left, top: MARGIN.top }}
        tw="absolute pointer-events-none"
      />
      <svg width={WIDTH + MARGIN.left + MARGIN.right} height={HEIGHT + MARGIN.top + MARGIN.bottom}>
        <g tw="fill-current" transform={`translate(${MARGIN.left}, ${MARGIN.top})`}>
          <g ref={brushEl} />
          <g ref={topAxis} />
          <g ref={leftAxis} />
        </g>
      </svg>
    </div>
  );
};

export default HoursChart;
