import { ascending, max } from 'd3-array';
import { rollup } from 'd3-array';
import { format } from 'd3-format';
import { scaleLinear } from 'd3-scale';
import ngram from 'n-gram-counter';
import React from 'react';
import 'twin.macro';
import { useLegendSheet } from '../sheets/useLegendSheet';

import { useActivityColor } from '../utils';

interface NGramCountsProps {
  data: any[];
  unit: 'counts' | '%';
}

const ActivityBadge = ({ color, ...props }) => {
  return (
    <span
      tw="inline-flex items-center justify-center h-5 w-5 rounded bg-opacity-50"
      css={{ backgroundColor: color }}
      {...props}
    />
  );
};

const NGramCounts = ({ data, unit = '%' }: NGramCountsProps) => {
  const { data: legend = [] } = useLegendSheet();
  const activities = legend.map(d => d.key);
  const activityColor = useActivityColor();

  const sortedData = data
    .map(d => ({
      activity: d.activity,
      date: new Date(`${d.date.toDateString()} ${d.time}`),
    }))
    .sort((a, b) => ascending(a.date, b.date))
    .map(d => d.activity);

  let nGrams = ngram({
    data: sortedData,
    debug: false,
    n: 2,
  }).map(([[from, to], count]) => ({ from, to, value: count }));

  // Calculate percentage based on counts
  if (unit === '%') {
    // Undefined values do not add up to total
    const total = nGrams.reduce((acc, d) => (d.from && d.to ? acc + d.value : acc), 0);

    nGrams = nGrams.map(({ from, to, value }) => ({
      from,
      to,
      value: value / total,
    }));
  }

  const tableData = rollup(
    nGrams,
    d => d[0].value,
    d => d.from,
    d => d.to,
  );

  const maxValue = max(
    nGrams.filter(d => d.from && d.to),
    d => d.value,
  );
  const colorScale = scaleLinear([0, maxValue], ['white', 'rgb(43,108,176)']);

  return (
    <table tw="text-center text-sm">
      <thead>
        <tr>
          <th rowSpan={2} colSpan={2}></th>
          <th colSpan={activities.length + 2} tw="font-normal text-base">
            From
          </th>
        </tr>
        <tr>
          {activities.map(a => (
            <th tw="w-8" key={a}>
              <ActivityBadge color={activityColor(a)} tw="mb-1">
                {a}
              </ActivityBadge>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {activities.map((a, i) => (
          <tr key={a}>
            {i === 0 && (
              <th rowSpan={activities.length} tw="font-normal text-base w-8">
                To
              </th>
            )}
            <th tw="w-8 h-8">
              <ActivityBadge color={activityColor(a)}>{a}</ActivityBadge>
            </th>
            {activities.map(b => {
              const val = tableData.get(a)?.get(b) || 0;
              return (
                <td
                  key={b}
                  css={{
                    backgroundColor: colorScale(val),
                    color: val > maxValue / 2 ? 'white' : 'black',
                  }}
                  tw="text-xs w-8 h-8 transition-colors duration-500"
                >
                  {unit === 'counts' ? val : format('.1f')(val * 100)}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default NGramCounts;
