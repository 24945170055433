import React from 'react';
import 'twin.macro';

const ActivityChartPlaceholder = ({ ...props }) => (
  <div
    tw="flex items-end animate-pulse"
    css={{ paddingTop: 20, paddingLeft: 40, paddingBottom: 20, height: 190 }}
    {...props}
  >
    {[135, 20, 60, 40, 100].map((d, i) => (
      <div key={i} css={{ marginRight: 3, width: 32, height: d }} tw="bg-gray-300" />
    ))}
  </div>
);

export default ActivityChartPlaceholder;
