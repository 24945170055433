import { extent, max } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { format } from 'd3-format';
import { scaleTime, scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { area, curveCatmullRom, line } from 'd3-shape';
import { timeHour, timeDay } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import React, { useRef, useEffect, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import 'twin.macro';

import { useGrowthStandard } from '../sheets/useGrowthStandard';
import { BodyMeasurement } from '../sheets/useMeasurementsSheet';

interface GrowthChartProps {
  data: BodyMeasurement[];
  gender: 'boy' | 'girl';
  parameter: 'length' | 'weight';
  zoomLevel?: 'weeks' | 'months' | 'years';
  startDate?: Date;
}

const MARGIN = {
  bottom: 50,
  top: 10,
  left: 40,
  right: 20,
};
const HEIGHT = 600;
const WIDTH = 600;
const BLUE = '#79A2C8';
const PINK = '#BD848D';
const BG_BLUE = '#F2F8FE';
const BG_PINK = '#FEF2F5';

const formatSd = d => (d ? format('+')(d) : 0);
const formatPercentile = d => ({ '-3': -0.01, '-2': 2, '-1': 16, 0: 50, 1: 84, 2: 98, 3: 99.9 }[d]);

const calcSD = (d, sd: number) => {
  const median = 'm' in d ? d.m : d.median;
  return 'sd' in d ? median + sd * d.sd : sd ? d[`${sd.toString()}sd`] : median;
};

const GrowthChart = ({ data, gender, parameter, zoomLevel, startDate, ...props }: GrowthChartProps) => {
  const hasValue = d => d.value !== undefined && !Number.isNaN(d.value);

  const primaryColor = { boy: BLUE, girl: PINK }[gender];
  const primaryBgColor = { boy: BG_BLUE, girl: BG_PINK }[gender];

  const formatValue = value => {
    if (parameter === 'weight') {
      return value < 10 ? `${format(',.0f')(value * 1000)} gram` : `${format('.1f')(value)} kg`;
    } else {
      return `${format('.1f')(value)} cm`;
    }
  };

  const reference = useGrowthStandard({
    gender,
    parameter,
    startDate: startDate || data?.[0].date,
    zoomLevel,
  });

  const referenceZoomed = reference.filter(d => ({ weeks: 'week', months: 'month', years: 'month' }[zoomLevel] in d));

  const parameterData = data.map(d => ({ date: d.date, value: d[parameter] })).filter(hasValue);
  const xAxisUnit = { length: 'cm', weight: 'kg' }[parameter];
  const yUnit = {
    weeks: 'age in weeks',
    months: 'age in months',
    years: 'age in months and years',
  }[zoomLevel];

  const bottomAxis = useRef(null);
  const leftAxis = useRef(null);

  const scaleX = scaleTime(
    extent(referenceZoomed, d => d.date),
    [0, WIDTH],
  );
  const scaleY = scaleLinear(
    {
      length: { weeks: [34, 82], months: [40, 100] }[zoomLevel],
      weight: { weeks: [0, 10], years: [0, 30] }[zoomLevel],
    }[parameter],

    [HEIGHT, 0],
  );

  const xAxis = axisBottom()
    .scale(scaleX)
    .tickFormat((_, i) => {
      if (zoomLevel === 'years') {
        // Show number of years or months every gridline
        return !i || i % 3 ? '' : i % 12 || i / 12;
      } else {
        // Show number of months every other gridline
        return !i || i % 2 ? '' : i / 2;
      }
    })
    .tickSize(-HEIGHT)
    .tickValues(
      referenceZoomed.flatMap(d => {
        if (zoomLevel === 'years') {
          return d.date;
        }
        // Add ticks on half weeks/months
        const offsetDate = {
          weeks: timeHour.offset(d.date, 3.5 * 24),
          months: timeDay.offset(d.date, 15),
        }[zoomLevel];
        return [d.date, offsetDate];
      }),
    );
  const yAxis = axisLeft().scale(scaleY).ticks(30).tickFormat(format('')).tickSize(-WIDTH);

  const path = sd =>
    line()
      .x(d => scaleX(d.date))
      .y(d => scaleY(calcSD(d, sd)))
      .curve(curveCatmullRom.alpha(1))(reference);

  const pathArea = area()
    .x(d => scaleX(d.date))
    .y0(d => scaleY(calcSD(d, -2)))
    .y1(d => scaleY(calcSD(d, 2)))
    .curve(curveCatmullRom.alpha(1))(reference);

  const dataPath = line()
    .x(d => scaleX(d.date))
    .y(d => scaleY(d.value))(parameterData);

  useEffect(() => {
    const xAxisEl = select(bottomAxis.current).transition().duration(500).call(xAxis);
    xAxisEl
      .selectAll('.tick line')
      .attr('stroke', primaryColor)
      .attr('stroke-width', (d, i) => (i % 2 ? 0.5 : 0.75));
    if (zoomLevel === 'years') {
      xAxisEl
        .selectAll('.tick text')
        //Could not select using i because .call(xAxis) is still in progress
        .filter(d => d.getMonth() === startDate.getMonth())
        .style('font-size', '14')
        .style('font-weight', 'bold');
    }

    const yAxisEl = select(leftAxis.current).transition().duration(500).call(yAxis);
    yAxisEl.select('.domain').attr('stroke', primaryColor);
    yAxisEl
      .selectAll('.tick line')
      .attr('stroke', primaryColor)
      .attr('stroke-width', (d, i) => (i % 2 ? 0.5 : 0.75));
  }, [bottomAxis.current, leftAxis.current, zoomLevel]);

  const referenceLines = [
    [3, 0.5],
    [2, 0.75],
    [1, 0.75],
    [0, 1.5],
    [-3, 0.5],
    [-2, 0.75],
    [-1, 0.75],
  ];

  const sdLabel = referenceZoomed[referenceZoomed.length - { weeks: 2, months: 3, years: 6 }[zoomLevel]];
  const sdLabelBg = sd => (-1 <= sd && sd <= 1 ? primaryBgColor : sd === 2 || sd === -2 ? 'transparent' : 'white');
  const percentileLabel = referenceZoomed[referenceZoomed.length - 1];
  const PLABEL_HEIGHT = 20;
  const GRID_CELL_WIDTH = WIDTH / { weeks: 28, months: 24, years: 20 }[zoomLevel];

  return (
    <div {...props}>
      <svg width={WIDTH + MARGIN.left + MARGIN.right} height={HEIGHT + MARGIN.top + MARGIN.bottom}>
        <clipPath id={`clip-${parameter}`}>
          <rect width={WIDTH + MARGIN.left + 1} height={HEIGHT + MARGIN.top + MARGIN.bottom} />
          <rect y={HEIGHT + MARGIN.top} width={WIDTH + MARGIN.left + MARGIN.right} height={MARGIN.bottom} />
        </clipPath>
        <g clipPath={`url(#clip-${parameter})`}>
          <text fontSize={10} textAnchor="start" y={MARGIN.top} dy="0.34em">
            {xAxisUnit.toUpperCase()}
          </text>
          <text fontSize={10} textAnchor="start" x={MARGIN.left} y={HEIGHT + MARGIN.top + 25} dy="0.34em">
            {yUnit.toUpperCase()}
          </text>
          <g transform={`translate(${MARGIN.left}, ${MARGIN.top})`}>
            <g fill="transparent" stroke={primaryColor}>
              <path d={pathArea} fill={primaryBgColor} strokeWidth="0.25" tw="transition-all duration-500" />
              {referenceLines.map(([sd, strokeWidth]) => (
                <path key={sd} d={path(sd)} strokeWidth={strokeWidth} tw="transition-all duration-500" />
              ))}

              <path d={dataPath} opacity={0.75} strokeWidth="2" tw="transition-all duration-500 stroke-current" />
            </g>

            <g ref={bottomAxis} transform={`translate(0, ${HEIGHT})`} fontFamily={null} />
            <g ref={leftAxis} fontFamily="" />

            <g
              transform={`translate(${scaleX(percentileLabel.date) - GRID_CELL_WIDTH},${
                scaleY(calcSD(percentileLabel, 3)) - PLABEL_HEIGHT - 10
              })`}
              tw="transition-all duration-500"
            >
              {/* P square */}
              <rect
                tw="transition-all duration-500"
                width={GRID_CELL_WIDTH}
                height={PLABEL_HEIGHT}
                fill={primaryColor}
              />
              <text
                transform={`translate(${GRID_CELL_WIDTH / 2},${PLABEL_HEIGHT / 2})`}
                dy="0.35em"
                fill="white"
                fontSize="10"
                textAnchor="middle"
              >
                P
              </text>
              {/* White box */}
              <rect
                tw="transition-all duration-500"
                transform={`translate(0,20)`}
                width={GRID_CELL_WIDTH}
                height={scaleY(calcSD(percentileLabel, -3)) - scaleY(calcSD(percentileLabel, 3)) + PLABEL_HEIGHT}
                stroke={primaryColor}
                strokeWidth={0.5}
                fill="white"
              />
            </g>
            {/* Percentiles */}
            {referenceLines.map(([sd]) => (
              <text
                key={sd}
                transform={`translate(${scaleX(percentileLabel.date) - GRID_CELL_WIDTH / 2},${scaleY(
                  calcSD(percentileLabel, sd),
                )})`}
                dy="0.35em"
                fontSize="9"
                textAnchor="middle"
                tw="transition-all duration-500"
              >
                {formatPercentile(sd)}
              </text>
            ))}

            {referenceLines.map(([sd]) => (
              <g
                key={sd}
                transform={`translate(${scaleX(sdLabel.date)},${scaleY(calcSD(sdLabel, sd))})`}
                tw="transition-all duration-500"
              >
                <rect transform="translate(-10,-10)" width="1.5em" height="1.25em" stroke="none" fill={sdLabelBg(sd)} />
                <text dy="0.35em" fontSize="10" fontWeight="bold" textAnchor="middle">
                  {formatSd(sd)}
                </text>
              </g>
            ))}

            {parameterData.map(d => (
              <circle
                key={+d.date}
                r="3.5"
                cx={scaleX(d.date)}
                cy={scaleY(d.value)}
                data-for={`growthtip-${parameter}`}
                data-html
                data-tip={`<strong>${timeFormat('%e %B %Y %H:%M')(d.date)}</strong><br/>${formatValue(d.value)}`}
                tw="transition-all duration-500"
              />
            ))}
          </g>
        </g>
      </svg>
      <ReactTooltip tw="p-1" id={`growthtip-${parameter}`} />
    </div>
  );
};
export default GrowthChart;
