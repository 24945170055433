import React from 'react';
import 'twin.macro';

export const ToggleLink = ({ children, options: [first, second], setValue, value, ...props }) => (
  <span
    onClick={() => setValue(value === first ? second : first)}
    tw="cursor-pointer relative hover:text-blue-600 after:(content absolute h-1 left-0 w-full bg-blue-500 rounded) hover:after:bg-blue-400"
    css={{
      '::after': {
        bottom: -2,
      },
    }}
    {...props}
  >
    {children}
  </span>
);
