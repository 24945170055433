import { Temperature } from '../weather/influx-weather-api';
import { matchRange } from './matchRange';
import { SheetError, useSheet } from './useSheet';

export interface BodyMeasurement {
  date: Date;
  length: number;
  weight: number;
  temperature?: Temperature;
}

export const useMeasurementsSheet = (config: {
  range?: string;
  spreadsheetId?: string;
}): {
  data?: BodyMeasurement[];
  error?: SheetError;
  pending?: boolean;
} => {
  const { data, error, pending } = useSheet(config);

  const [colStart, rowStart] = matchRange(config.range);

  let formattedData;
  if (data) {
    const [columnNames, ...rows] = data;
    formattedData = rows.reduce((arr, [date, c, length, weight]: [string, string, string, string], i) => {
      const parseableDate = (date ?? '').replace(' ', ' ');
      if (isNaN(new Date(parseableDate).valueOf())) {
        console.warn(`Could not parse date in body measurements table in cell ${colStart}${i + 1 + +rowStart}:`, date);
      } else if (!isFinite(+c)) {
        console.warn(
          `Could not parse temperature in body measurements table in cell ${String.fromCharCode(
            colStart.charCodeAt(0) + 1,
          )}${i + 1 + +rowStart}:`,
          c,
        );
      } else if (length && !isFinite(+length)) {
        console.warn(
          `Could not parse length in body measurements table in cell ${String.fromCharCode(
            colStart.charCodeAt(0) + 2,
          )}${i + 1 + +rowStart}:`,
          length,
        );
      } else if (weight && !isFinite(+weight)) {
        console.warn(
          `Could not parse weight in body measurements table in cell ${String.fromCharCode(
            colStart.charCodeAt(0) + 3,
          )}${i + 1 + +rowStart}:`,
          weight,
        );
      } else {
        arr.push({
          date: new Date(parseableDate),
          length: length ? +length : undefined,
          temperature: c ? { c: +c, f: +c * 1.8 + 32 } : undefined,
          weight: weight ? +weight : undefined,
        });
      }
      return arr;
    }, [] as BodyMeasurement[]);
  }

  return { data: formattedData, error, pending };
};
