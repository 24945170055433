import { NaturallyOrderedValue, Group, Dimension } from 'crossfilter2';
import { axisBottom, axisLeft } from 'd3-axis';
import { brushX } from 'd3-brush';
import { scaleBand, scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { stack } from 'd3-shape';
import { timeDays, timeMonday } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import React, { useEffect, useRef, useState, useContext } from 'react';
import 'twin.macro';

import {
  Datum,
  getDomainMax,
  getHoursCountGroup,
  getPercentageGroup,
  percentageAxis,
  useActivityColor,
} from '../utils';
import { CFContext } from '../CrossFilterContext';
import { useLegendSheet } from '../sheets/useLegendSheet';

interface WeekDaysChartProps {
  unit: 'hours' | '%';
}

const MARGIN = {
  bottom: 30,
  top: 10,
  left: 40,
};

const HEIGHT = 150;
const WIDTH = 200;

const dayNames = timeDays(timeMonday(), timeMonday.ceil(new Date())).map(timeFormat('%a'));

function getGroup(unit, dimension) {
  if (dimension) {
    switch (unit) {
      case 'hours':
        return getHoursCountGroup(dimension);
      case '%':
        return getPercentageGroup(dimension);
    }
  }
}

function getValue(d, key, unit) {
  switch (unit) {
    case 'hours':
      return d[key];
    case '%':
      return d.activities[key].avg;
    default:
      return 0;
  }
}

const WeekDaysChart = ({ unit = '%', ...props }: WeekDaysChartProps) => {
  const { data: legend = [] } = useLegendSheet();
  const activities = legend.map(d => d.key);
  const activityColor = useActivityColor();

  const { cf } = useContext(CFContext);

  const [dimension, setDimension] = useState<Dimension<Datum, string>>();

  const weekDay = timeFormat('%u'); // 1-7 = Mon-Sun
  useEffect(() => {
    const dimension = cf.dimension(d => +weekDay(d.date) - 1);

    setDimension(dimension);
  }, [cf]);

  const group: Group<Datum, NaturallyOrderedValue, unknown> = getGroup(unit, dimension);
  const data = group ? group.all() : [];

  const leftAxis = useRef(null);
  const bottomAxis = useRef(null);

  const scaleX = scaleBand([0, 1, 2, 3, 4, 5, 6], [0, WIDTH]).round(true);
  const scaleXLin = scaleLinear([0, 7], [0, WIDTH]);

  const scaleY = scaleLinear([0, getDomainMax(unit, data)], [HEIGHT, 0]);

  const xAxis = axisBottom()
    .scale(scaleX)
    .tickFormat(d => dayNames[d]);
  const yAxis = unit === 'hours' ? axisLeft().scale(scaleY) : percentageAxis(axisLeft).scale(scaleY);
  const stackedData = stack()
    .keys(activities)
    .value((d, key) => getValue(d.value, key, unit))(data);

  useEffect(() => {
    select(bottomAxis.current).transition().duration(450).call(xAxis).select('.domain').style('display', 'none');
    select(leftAxis.current).transition().duration(450).call(yAxis).select('.domain').style('display', 'none');
  });

  const brushEl = useRef(null);

  const brush = brushX()
    .extent([
      [0, 0],
      [WIDTH, HEIGHT],
    ])
    .on('end', onBrushEnd);

  function onBrushEnd(event) {
    // Programmatic brush move (rounding)
    if (!event.sourceEvent) {
      return;
    }

    // Empty selection (clicking on canvas)
    if (!event.selection) {
      dimension.filter(null);
      return;
    }
    const [x0, x1] = event.selection.map(d => Math.round(scaleXLin.invert(d)));

    select(this)
      .transition()
      .call(brush.move, x0 < x1 ? [x0, x1].map(scaleXLin) : null);
    dimension.filter(x0 < x1 ? [x0, x1] : null);
  }

  select(brushEl.current).call(brush);

  return (
    <div {...props}>
      <svg width={WIDTH + MARGIN.left} height={HEIGHT + MARGIN.top + MARGIN.bottom}>
        <g tw="fill-current" transform={`translate(${MARGIN.left}, ${MARGIN.top})`}>
          {stackedData.map((layer, i) => (
            <g key={layer.key}>
              {layer.map((d, j) => (
                <rect
                  key={j}
                  height={scaleY(d[0]) - scaleY(d[1])}
                  width={scaleX.step() - 1}
                  x={scaleX(+d.data.key)}
                  y={scaleY(d[1])}
                  css={[
                    {
                      color: activityColor(layer.key),
                      transition: 'height 450ms, y 450ms',
                    },
                  ]}
                >
                  <title>
                    {legend.find(l => l.key === layer.key)?.label} {d[1] - d[0]} hour(s)
                  </title>
                </rect>
              ))}
            </g>
          ))}
          <g ref={brushEl} />
        </g>
        <g ref={bottomAxis} transform={`translate(${MARGIN.left}, ${HEIGHT + MARGIN.top})`}></g>
        <g ref={leftAxis} transform={`translate(${MARGIN.left}, ${MARGIN.top})`}></g>
      </svg>
    </div>
  );
};

export default WeekDaysChart;
