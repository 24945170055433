import { extent } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { format } from 'd3-format';
import { interpolateArray } from 'd3-interpolate';
import { quadtree } from 'd3-quadtree';
import { ScaleLinear, scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { timeFormat } from 'd3-time-format';
import React, { useRef, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import 'twin.macro';

import { Temperature, TemperatureUnit } from '../weather/influx-weather-api';
import { useActivityColor, useActivityLabel } from '../utils';
import { useInterpolation } from '../useInterpolation';
import { useHighDPIContext } from '../useHighDPIContext';

const MARGIN = {
  top: 10,
  right: 20,
  bottom: 20,
  left: 40,
};

const WIDTH = 200;
const HEIGHT = 200;

const formatPerc = format('.0%');
const formatTemp = format('.1f');

interface ScatterDatum {
  key: string;
  temperature: Temperature;
  value: { activities: { s: Val; d: Val; w: Val; h: Val } };
  count: number;
}

type QuadtreeNode = [number, number, string, string]; // x, y, activity, date

interface ScatterPlotProps {
  data: ScatterDatum[];
  scaleY: ScaleLinear<number, number>;
  unit?: TemperatureUnit;
}

interface Val {
  count: number;
  sum: number;
  avg: number;
}

const ScatterPlot = ({ data, scaleY, unit = 'c', ...props }: ScatterPlotProps) => {
  const identifier = useRef(Math.random() * 1000);
  const activityLabel = useActivityLabel();
  const activityColor = useActivityColor();

  const canvasRef = useRef(null);
  const leftAxis = useRef(null);
  const bottomAxis = useRef(null);
  const hoveredDatum = useRef<QuadtreeNode>(null);

  const scaleX = scaleLinear([0, 1], [0, WIDTH]);

  const xAxis = axisBottom().scale(scaleX).tickFormat(format('.0%')).ticks(5);
  const yAxis = axisLeft().scale(scaleY).tickPadding(10).ticks(6);

  const flattenedData = data.flatMap(d =>
    Object.entries(d.value.activities).map(
      ([activity, v]): QuadtreeNode => [scaleX(v.avg), scaleY(d.temperature[unit]), activity, d.key],
    ),
  );

  const tree = quadtree(flattenedData);

  useEffect(() => {
    select(bottomAxis.current).call(xAxis).select('.domain');
    select(leftAxis.current).call(yAxis).select('.domain');
  }, [unit, bottomAxis, leftAxis, flattenedData]);

  const interpolatedData = useInterpolation(flattenedData, 450, interpolateArray);

  useEffect(() => {
    const context = useHighDPIContext(canvasRef.current);

    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    interpolatedData.forEach(([x, y, activity]) => {
      context.beginPath();
      context.fillStyle = activityColor(activity).replace('var(--tw-text-opacity)', '0.4');
      context.arc(x, y, 3, 0, 2 * Math.PI);
      context.fill();
    });
  });

  return (
    <div tw="relative" {...props}>
      <canvas
        ref={canvasRef}
        css={{ height: HEIGHT, width: WIDTH, left: MARGIN.left, top: MARGIN.top }}
        tw="absolute"
        data-for={`scattertip-${identifier.current}`}
        data-html
        data-tip
      />
      <svg
        width={WIDTH + MARGIN.left + MARGIN.right}
        height={HEIGHT + MARGIN.top + MARGIN.bottom}
        tw="relative pointer-events-none"
      >
        <g tw="fill-current" transform={`translate(${MARGIN.left}, ${MARGIN.top})`}>
          <g ref={leftAxis}></g>
          <g ref={bottomAxis} transform={`translate(0, ${HEIGHT})`}></g>
        </g>
      </svg>
      {
        <ReactTooltip
          tw="p-1"
          id={`scattertip-${identifier.current}`}
          effect="float"
          overridePosition={({ left, top }, currentEvent: MouseEvent, currentTarget: Element) => {
            hoveredDatum.current = tree.find(
              currentEvent.clientX - currentTarget.getBoundingClientRect().left,
              currentEvent.clientY - currentTarget.getBoundingClientRect().top,
              5,
            );

            // Show outside view because we cannot hide the tooltip when there is no data point
            return hoveredDatum.current ? { left, top } : { left: 999, top: 9999 };
          }}
          getContent={() => {
            if (!hoveredDatum.current) {
              return 'No data';
            }

            const [x, y, activity, date] = hoveredDatum.current;

            return `<strong>${timeFormat('%e %B %Y')(date)}</strong><br/>${formatTemp(
              scaleY.invert(y),
            )} &deg;${unit.toUpperCase()} - ${formatPerc(scaleX.invert(x))} ${activityLabel(activity)}`;
          }}
        />
      }
    </div>
  );
};

export default ScatterPlot;
