import React from 'react';
import 'twin.macro';

const TempError = error => (
  <p tw="text-red-400 text-sm px-2 py-1 bg-red-100 border-red-200 border-l-4 inline-block">
    Error fetching temperatures: {error.error.message}
  </p>
);

export default TempError;
