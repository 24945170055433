import React, { forwardRef } from 'react';
import 'twin.macro';

import WeatherChart from './WeatherChart';
import WeatherChartPlaceholder from './WeatherChartPlaceholder';
import ErrorMessage from '../../ErrorMessage';
import { Hint } from '../../Hint';
import { Title } from '../../Title';
import TempError from '../TempError';
import { TemperatureUnit } from '../weather-api';
import { ToggleLink } from '../../ToggleLink';
import { SheetError } from '../../sheets/useSheet';

interface WeatherContainerProps {
  data: any[];
  error: Error;
  isPending: boolean;
  ref: any;
  setUnit: (unit: TemperatureUnit) => void;
  sheetError: SheetError;
  unit?: TemperatureUnit;
}

const WeatherContainer = (
  { data, error, isPending, setUnit, sheetError, unit = 'c', ...props }: WeatherContainerProps,
  ref,
) => {
  return (
    <div {...props}>
      <Title>
        Max temperature in{' '}
        <ToggleLink options={['c', 'f']} setValue={setUnit} value={unit} tw="uppercase">
          &deg;{unit}
        </ToggleLink>
      </Title>
      <Hint>Backyard, Roelofarendsveen, NL</Hint>
      {isPending && <WeatherChartPlaceholder />}
      {sheetError && <ErrorMessage>Error loading sheet</ErrorMessage>}
      {error && <TempError error={error} />}
      {!sheetError && !error && !isPending && data && (
        <WeatherChart data={data} ref={ref} unit={unit} tw="overflow-x-auto" />
      )}
    </div>
  );
};

export default forwardRef(WeatherContainer);
