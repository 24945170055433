import React, { useContext, useState } from 'react';

import NGramCounts from './NGramCounts';
import NGramCountsPlaceholder from './NGramCountsPlaceholder';
import { CFContext } from '../CrossFilterContext';
import ErrorMessage from '../ErrorMessage';
import { Hint } from '../Hint';
import { Title } from '../Title';
import { ToggleLink } from '../ToggleLink';

const NGramsContainer = ({ ...props }) => {
  const { cf, error } = useContext(CFContext);
  const [unit, setUnit] = useState<'counts' | '%'>('%');

  return (
    <div {...props}>
      <Title>
        Activity state changes in{' '}
        <ToggleLink options={['counts', '%']} setValue={setUnit} value={unit}>
          {unit}
        </ToggleLink>
      </Title>
      <Hint>State changes from/to unknown activity are removed</Hint>
      {error ? (
        <ErrorMessage>Error loading sheet</ErrorMessage>
      ) : !cf ? (
        <NGramCountsPlaceholder />
      ) : (
        <NGramCounts
          data={cf.all().map((d, i) => ({
            ...d,
            activity: cf.isElementFiltered(i) ? d.activity : undefined,
          }))}
          unit={unit}
        />
      )}
    </div>
  );
};

export default NGramsContainer;
