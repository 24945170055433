import { InfluxDB } from '@influxdata/influxdb-client-browser';
import { range } from 'd3-array';
import { timeDays } from 'd3-time';
import { useEffect, useState } from 'react';

export type TemperatureUnit = 'c' | 'f';

export interface Temperature {
  c?: number;
  f?: number;
}
export interface WeatherDatapoint {
  date: Date;
  maxTemperature: Temperature;
}

const influxdb = new InfluxDB({
  url: 'https://home.frankvanwijk.nl:8086',
  token: '2A_OY3yvDTwk2irr-_Stp9PftgHtE3aICrWj4cqrty0D8HqNNmo_HE0i5EMTDJNjD5xnhMzE3_ixHnmRZyjAmg==',
});
const queryApi = influxdb.getQueryApi('Home');

export const useWeather = dateDomain => {
  const [data, setData] = useState<WeatherDatapoint[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!dateDomain) {
      return;
    }

    // Select all max temps but create empty values for the days that are missing
    // We need to shift the time one day back because the aggregateWindow sets the time to the end of the window
    const fluxQuery = `
  import "timezone"
  
  option location = timezone.location(name: "Europe/Amsterdam")

  params = {
    start: ${new Date(dateDomain[0]).toISOString()},
    stop: ${new Date(dateDomain[1]).toISOString()}
  }
  
  from(bucket: "aggregates")
  |> range(start: params.start, stop: params.stop)
  |> filter(fn: (r) => r["_measurement"] == "max")
  |> filter(fn: (r) => r["_field"] == "temperatureOut")
  |> aggregateWindow(every: 1d, fn: mean, createEmpty: true)
  |> timeShift(duration: -1d)
  |> yield(name: "max")`;

    let allData: Record<string, any>[] = [];
    queryApi.queryRows(fluxQuery, {
      next(row, tableMeta) {
        const rowData = tableMeta.toObject(row);
        allData = [...allData, rowData];
      },
      error(e) {
        setError(e);
      },
      complete() {
        if (allData.length) {
          setData(
            allData.map(d => ({
              date: new Date(d._time),
              maxTemperature: d._value ? { c: (+d._value - 32) / 1.8, f: d._value } : undefined,
            })),
          );
        } else {
          setData(timeDays.apply(this, dateDomain).map(date => ({ date, maxTemperature: undefined })));
        }
        setIsLoading(false);
      },
    });
  }, [dateDomain]);

  return { isLoading, data, error };
};
