import { useContext, useMemo } from 'react';

import { CFContext } from '../CrossFilterContext';
import { getDateDomain } from '../utils';

export const useActivityDomain = (): [Date, Date] => {
  const { cf } = useContext(CFContext);
  const data = cf && cf.all();

  return useMemo(() => (data ? getDateDomain(data) : undefined), [data]);
};
