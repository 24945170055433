import { extent } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { format } from 'd3-format';
import { quadtree } from 'd3-quadtree';
import { scaleBand, scaleLinear, scaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { curveMonotoneX, line } from 'd3-shape';
import { timeFormat } from 'd3-time-format';
import { addHours } from 'date-fns';
import React, { forwardRef, useRef, useEffect, useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import 'twin.macro';

import { ConfigContext } from '../../ConfigContext';
import { GridLines } from '../../GridLines';
import { useHighDPIContext } from '../../useHighDPIContext';
import { formatDay } from '../../utils';
import { Temperature, TemperatureUnit } from '../influx-weather-api';

const DAY_WIDTH = 30;
const HEIGHT = 200;
const MARGIN = {
  bottom: 50,
  top: 10,
  left: 40,
  right: 20,
};

const formatTemp = format('.1f');

interface WeatherDatumOptional {
  date: Date;
  maxTemperature?: Temperature;
}

type QuadtreeNode = [number, number, string, string]; // x, y, date, temp

interface WeatherChartProps {
  data: WeatherDatumOptional[];
  unit?: TemperatureUnit;
}

const WeatherChart = ({ data, unit = 'c', ...props }: WeatherChartProps, ref) => {
  const bottomAxis = useRef(null);
  const leftAxis = useRef(null);
  const canvasRef = useRef(null);
  const hoveredDatum = useRef<QuadtreeNode>(null);

  const { schemaWidth } = useContext(ConfigContext);

  const WIDTH =
    schemaWidth === 'default' ? data.length * DAY_WIDTH : window.innerWidth - MARGIN.left - MARGIN.right - 349;

  const dateExtent = data.length ? extent(data, d => d.date) : [new Date(), new Date()];

  // Shift axis to the right with 0.5 step to mimick scaleBand
  const xAxisDomain = [addHours(dateExtent[0], -12), addHours(dateExtent[1], +12)];
  const scaleX = scaleTime(xAxisDomain, [0, WIDTH]);

  const scaleY = scaleLinear(
    extent(data, (d: WeatherDatumOptional) => d.maxTemperature?.[unit]).map((d, i, arr) => {
      const span = (arr[1] - arr[0]) * 0.1;
      return i ? d + span : Math.min(unit === 'c' ? 0 : 32, d - span);
    }),
    [HEIGHT, 0],
  );

  const xAxis =
    schemaWidth === 'default'
      ? axisBottom().scale(scaleX).tickFormat(formatDay).ticks(data.length)
      : axisBottom().scale(scaleX);
  const yAxis = axisLeft().scale(scaleY).ticks(6);

  useEffect(() => {
    select(bottomAxis.current).call(xAxis);
    select(leftAxis.current).call(yAxis);
  }, [canvasRef.current, bottomAxis.current, leftAxis.current, xAxis, yAxis]);

  const hasTemp = d => d.maxTemperature?.[unit] !== undefined;
  const filteredData = data.filter(hasTemp);

  const tree = quadtree(
    filteredData.map(d => [scaleX(d.date), scaleY(d.maxTemperature[unit]), d.date, d.maxTemperature[unit]]),
  );

  useEffect(() => {
    const context = useHighDPIContext(canvasRef.current);

    // Line
    context.lineWidth = 2;
    line()
      .defined(hasTemp)
      .x(d => scaleX(d.date))
      .y(d => scaleY(d.maxTemperature?.[unit]))
      .curve(curveMonotoneX)
      .context(context)(data);
    context.strokeStyle = 'rgba(0,0,0,0.25)';
    context.stroke();

    // Circles
    const r = schemaWidth === 'default' ? 4 : 2.5;
    data.filter(hasTemp).forEach(d => {
      context.beginPath();
      context.arc(scaleX(d.date), scaleY(d.maxTemperature?.[unit]), r, 0, 2 * Math.PI);
      context.fill();
    });
  }, [canvasRef.current, tree]);

  return (
    <div ref={ref} tw="relative" {...props}>
      <canvas
        ref={canvasRef}
        data-for="weathertip"
        data-html
        data-tip
        css={{ height: HEIGHT, width: WIDTH, left: MARGIN.left, top: MARGIN.top }}
        tw="absolute"
      />
      <svg width={WIDTH + MARGIN.left + MARGIN.right} height={HEIGHT + MARGIN.top + MARGIN.bottom}>
        <GridLines
          ticks={scaleY
            .ticks()
            .slice(1)
            .map(tick => scaleY(tick))}
          transform={`translate(${MARGIN.left}, ${MARGIN.top})`}
          width={WIDTH}
        />
        <g ref={bottomAxis} transform={`translate(${MARGIN.left}, ${HEIGHT + MARGIN.top})`}></g>
        <g ref={leftAxis} transform={`translate(${MARGIN.left}, ${MARGIN.top})`}></g>
      </svg>
      <ReactTooltip
        tw="p-1"
        id="weathertip"
        effect="float"
        overridePosition={({ left, top }, currentEvent: MouseEvent, currentTarget: Element) => {
          hoveredDatum.current = tree.find(
            currentEvent.clientX - currentTarget.getBoundingClientRect().left,
            currentEvent.clientY - currentTarget.getBoundingClientRect().top,
            5,
          );

          // Show outside view because we cannot hide the tooltip when there is no data point
          return hoveredDatum.current ? { left, top } : { left: 999, top: 9999 };
        }}
        getContent={() => {
          if (!hoveredDatum.current) {
            return 'No data';
          }

          const [x, y, date, temperature] = hoveredDatum.current;

          return `<strong>${timeFormat('%e %B %Y')(date)}</strong><br/>${formatTemp(
            temperature,
          )} &deg;${unit.toUpperCase()}`;
        }}
      />
    </div>
  );
};

export default forwardRef(WeatherChart);
