import { max } from 'd3-array';
import React, { useContext, useState } from 'react';
import 'twin.macro';

import { ConfigContext } from '../ConfigContext';
import ErrorMessage from '../ErrorMessage';
import { Title } from '../Title';
import { useMeasurementsSheet } from '../sheets/useMeasurementsSheet';
import GrowthChartPlaceholder from './GrowthChartPlaceholder';
import GrowthChart from './GrowthChart';
import { Hint } from '../Hint';
import { ToggleButtons } from '../ToggleButtons';
import { useEffect } from 'react';
import { usePrevious } from '../useInterpolation';

const BodyMeasurementsContainer = ({ ...props }) => {
  const { spreadsheetId, bodyRange: range, birthDate, gender } = useContext(ConfigContext);
  const { data, error, pending } = useMeasurementsSheet({
    spreadsheetId,
    range,
  });

  const startDate = birthDate || data?.[0].date;

  const [lengthZoomLevel, setLengthZoomLevel] = useState<'weeks' | 'months' | 'years'>('weeks');
  const [weightZoomLevel, setWeightZoomLevel] = useState<'weeks' | 'months' | 'years'>('weeks');

  const prevData = usePrevious(data);

  useEffect(() => {
    // Continue when we have data but previous render we didn't have data
    if (prevData || !data) {
      return;
    }
    const maxLengthDate = max(
      data.filter(d => d.length),
      d => d.date,
    );

    const lengthDaySpan = (maxLengthDate - +new Date(startDate)) / 1000 / 60 / 60 / 24;
    if (lengthDaySpan > 13 * 7) {
      setLengthZoomLevel('months');
    }

    const maxWeightDate = max(
      data.filter(d => d.weight),
      d => d.date,
    );
    const weightDaySpan = (maxWeightDate - +new Date(startDate)) / 1000 / 60 / 60 / 24;
    if (weightDaySpan > 13 * 7) {
      setWeightZoomLevel('years');
    }
  }, [prevData, data, startDate]);

  return (
    <div tw="p-4 grid gap-16 grid-flow-col justify-start" {...props}>
      <div>
        <Title>Body length in cm</Title>
        <Hint>WHO child growth standards 2011</Hint>
        <ToggleButtons
          onChange={setLengthZoomLevel}
          options={[
            { label: '13 weeks', value: 'weeks' },
            { label: '24 months', value: 'months' },
          ]}
          value={lengthZoomLevel}
          tw="mb-4"
        />

        {error ? (
          <ErrorMessage>Error loading sheet</ErrorMessage>
        ) : pending ? (
          <GrowthChartPlaceholder />
        ) : (
          <GrowthChart
            data={data}
            gender={gender as 'boy' | 'girl'}
            parameter="length"
            startDate={startDate && new Date(startDate)}
            zoomLevel={lengthZoomLevel}
          />
        )}
      </div>
      <div>
        <Title>Body weight in kg</Title>
        <Hint>WHO child growth standards 2011</Hint>
        <ToggleButtons
          onChange={setWeightZoomLevel}
          options={[
            { label: '13 weeks', value: 'weeks' },
            { label: '5 years', value: 'years' },
          ]}
          value={weightZoomLevel}
          tw="mb-4"
        />
        {error ? (
          <ErrorMessage>Error loading sheet</ErrorMessage>
        ) : pending ? (
          <GrowthChartPlaceholder />
        ) : (
          <GrowthChart
            data={data}
            gender={gender as 'boy' | 'girl'}
            parameter="weight"
            startDate={startDate && new Date(startDate)}
            zoomLevel={weightZoomLevel}
          />
        )}
      </div>
    </div>
  );
};

export default BodyMeasurementsContainer;
