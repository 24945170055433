import React, { useContext, useState } from 'react';

import ErrorMessage from '../ErrorMessage';
import HoursChart from './HoursChart';
import HoursChartPlaceholder from './HoursChartPlaceholder';
import { CFContext } from '../CrossFilterContext';
import { Hint } from '../Hint';
import { Title } from '../Title';
import { ToggleLink } from '../ToggleLink';

const HoursContainer = () => {
  const { cf, error } = useContext(CFContext);
  const [unit, setUnit] = useState<'hours' | '%'>('%');

  return (
    <div>
      <Title>
        Activity per 15 mins in{' '}
        <ToggleLink options={['hours', '%']} setValue={setUnit} value={unit}>
          {unit}
        </ToggleLink>
      </Title>
      <Hint>Drag to filter</Hint>
      {error ? (
        <ErrorMessage>Error loading sheet</ErrorMessage>
      ) : cf ? (
        <HoursChart unit={unit} />
      ) : (
        <HoursChartPlaceholder />
      )}
    </div>
  );
};

export default HoursContainer;
