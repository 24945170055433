import { easeQuadInOut } from 'd3-ease';
import { interpolateNumber } from 'd3-interpolate';
import { timer } from 'd3-timer';
import { useEffect, useRef, useState } from 'react';

export function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export const useInterpolation = (to, duration = 1000, interpolateFn = interpolateNumber) => {
  let t;
  const from = usePrevious(to) || 0;
  const [newValue, setNewValue] = useState(to);

  useEffect(() => {
    const interpolate = interpolateFn(from, to);

    t = timer(elapsed => {
      const newVal = interpolate(easeQuadInOut(elapsed / duration));
      setNewValue(Array.isArray(to) ? [...newVal] : newVal); // Create unique reference
      if (elapsed > duration) {
        t.stop();
      }

      return () => t.stop();
    });
  }, [JSON.stringify(to)]);

  return newValue;
};
