import React from 'react';
import tw from 'twin.macro';
import { Button } from './Button';

export interface ToggleButtonsProps {
  onChange: (value: 'weeks' | 'months' | 'years') => void;
  options: { label: string; value: 'weeks' | 'months' | 'years' }[];
  value: string;
}

export const ToggleButtons = ({ onChange, options, value, ...props }: ToggleButtonsProps) => (
  <div role="group" tw="grid grid-flow-col gap-2 justify-start" {...props}>
    {options.map(option => (
      <Button
        key={option.value}
        onClick={() => onChange(option.value)}
        size="small"
        css={[value === option.value ? tw`border-white border-opacity-50` : tw`border-white`]}
      >
        {option.label}
      </Button>
    ))}
  </div>
);
