import React, { useContext } from 'react';
import { useIsFetching } from 'react-query';
import tw from 'twin.macro';

import { ConfigContext } from './ConfigContext';

export const Heading = () => {
  const config = useContext(ConfigContext);
  const isRefetching = useIsFetching({ stale: false });

  return (
    <h1 tw="text-2xl font-bold leading-tight">
      {config.name}'s Dashboard{' '}
      <span tw="inline-block" css={isRefetching && tw`animate-ping`}>
        🌞
      </span>
    </h1>
  );
};
