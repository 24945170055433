import React from 'react';
import 'twin.macro';

const ScatterPlotPlaceholder = () => {
  const circles = [
    [10, 20],
    [20, 30],
    [40, 35],
    [45, 60],
    [65, 80],
    [80, 100],
    [85, 110],
    [115, 120],
    [165, 130],
    [220, 195],
  ];
  return (
    <svg width="100%" height="230" tw="animate-pulse border-l-4 border-b-4 border-gray-300 text-gray-300">
      {circles.map((d, i) => (
        <circle key={i} r="3" fill="currentColor" cx={d[0]} cy={d[1]} />
      ))}
    </svg>
  );
};

export default ScatterPlotPlaceholder;
