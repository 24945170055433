import React, { forwardRef } from 'react';
import 'twin.macro';

import ErrorMessage from '../ErrorMessage';
import { Title } from '../Title';
import { ToggleLink } from '../ToggleLink';
import { SheetError } from '../sheets/useSheet';
import { BodyMeasurement } from '../sheets/useMeasurementsSheet';
import { TemperatureUnit } from '../weather/influx-weather-api';
import BodyTemperatureChart from './BodyTemperatureChart';
import BodyMeasurementsPlaceholder from '../weather/weatherchart/WeatherChartPlaceholder';
import { Hint } from '../Hint';

export interface BodyTemperatureContainerProps {
  data?: BodyMeasurement[];
  error?: SheetError;
  pending?: boolean;
  setUnit: (unit: TemperatureUnit) => void;
  unit: TemperatureUnit;
}

const BodyTemperatureContainer = (
  { data, error, pending, setUnit, unit, ...props }: BodyTemperatureContainerProps,
  ref,
) => {
  return (
    <div tw="relative" {...props}>
      <Title>
        Body temperature in{' '}
        <ToggleLink options={['c', 'f']} setValue={setUnit} value={unit} tw="uppercase">
          &deg;{unit}
        </ToggleLink>
      </Title>
      <Hint>Note that correlations are very hard to make</Hint>

      {error ? (
        <ErrorMessage>Error loading sheet</ErrorMessage>
      ) : pending ? (
        <BodyMeasurementsPlaceholder />
      ) : (
        <div tw="flex">
          <BodyTemperatureChart data={data} ref={ref} unit={unit} tw="overflow-x-auto" />
        </div>
      )}
    </div>
  );
};

export default forwardRef(BodyTemperatureContainer);
