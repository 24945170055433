import React from 'react';
import 'twin.macro';

import { placeholderFade } from '../utils';

const DaysChartPlaceholder = ({ ...props }) => (
  <div tw="flex animate-pulse" css={{ paddingTop: 20, paddingLeft: 40, height: 200 }} {...props}>
    {placeholderFade.map((d, i) => (
      <div key={i} css={[{ marginRight: 1, width: 50, height: 150 }, d]} tw="bg-gray-300" />
    ))}
  </div>
);

export default DaysChartPlaceholder;
